import cn from "clsx";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { useAuth, useUI } from "@middleware/hooks";
import { client as env } from "@config/env/client";
import { generateDate } from "@middleware/helpers";
import { Logo } from "@components/commun";
import { Link, Text } from "@components/ui";
import {
  URL_PAGE_AVIS,
  URL_PAGE_CGV,
  URL_PAGE_CONTACT,
  URL_PAGE_FAQ,
  URL_PAGE_MENTIONS,
  URL_PAGE_WOMEN,
} from "@middleware/constants";
import imgFacebook from "@static/images/footer/facebook.svg";
import imgInsta from "@static/images/footer/instagram.svg";
import imgYoutube from "@static/images/footer/youtube.svg";
import style from "./BottomFooter.module.scss";

export const BottomFooter = () => {
  const { t } = useTranslation("footer");
  const { isAuthenticated } = useAuth();
  const { openModal } = useUI();

  return (
    <>
      <div className={style.bottom} role="definition">
        <div className="lk-wrap">
          <div className={`lk-flex ${style.flex}`}>
            <div className={cn(style.col, style.collogo)}>
              <Logo type="footer" />
            </div>
            <div className={cn(style.col, style.choose)}>
              <Text variant="span">{t("links.chooseDietbon")}</Text>
              <ul>
                <li>
                  <Link href={URL_PAGE_WOMEN} className="simple">
                    {t("links.ourPrograms")}
                  </Link>
                </li>
                <li>
                  {isAuthenticated === false && (
                    <a href="#!" onClick={openModal}>
                      {t("links.logIn")}
                    </a>
                  )}
                </li>
              </ul>
            </div>
            <div className={cn(style.col, style.infos)}>
              <Text variant="span">{t("links.discover")}</Text>
              <ul>
                <li>
                  <Link href={URL_PAGE_AVIS} className="simple">
                    {t("links.customerReviews")}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    className="simple"
                    rel="noreferrer"
                    href={env.NEXT_PUBLIC_FACEBOOK_GROUP_URL}
                  >
                    {t("links.groupFacebook")}
                  </Link>
                </li>
                <li>
                  <Link
                    href="javascript:Didomi.preferences.show()"
                    className="simple"
                  >
                    {t("links.cookies")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className={style.col}>
              <Text variant="span">{t("links.info")}</Text>
              <ul>
                <li>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href={URL_PAGE_FAQ}
                    className="simple"
                  >
                    {t("links.faq")}
                  </Link>
                </li>
                <li>
                  <Link href={URL_PAGE_CONTACT} className="simple">
                    {t("links.contact")}
                  </Link>
                </li>
                <li>
                  <Link href={URL_PAGE_CGV} className="simple">
                    {t("links.cgv")}
                  </Link>
                </li>
                <li>
                  <Link href={URL_PAGE_MENTIONS} className="simple">
                    {t("links.mentions")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={style.last}>
            <div className={`lk-flex ${style.flex}`}>
              <div className={style.reseaux}>
                <ul>
                  <li>
                    <Link
                      className="simple"
                      target="_blank"
                      rel="noreferrer"
                      href={env.NEXT_PUBLIC_FACEBOOK_URL}
                    >
                      <Image
                        alt="Kitchen daily"
                        src={imgFacebook}
                        width="25"
                        height="26"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="simple"
                      target="_blank"
                      rel="noreferrer"
                      href={env.NEXT_PUBLIC_INSTAGRAM_URL}
                    >
                      <Image
                        alt="Kitchen daily"
                        src={imgInsta}
                        width="25"
                        height="26"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="simple"
                      target="_blank"
                      rel="noreferrer"
                      href={env.NEXT_PUBLIC_YOUTUBE_URL}
                    >
                      <Image
                        alt="Kitchen daily"
                        src={imgYoutube}
                        width="25"
                        height="26"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className={style.complement}>
                <Text variant="p">
                  {t("reseaux.brand", {
                    year: generateDate().getFullYear(),
                  })}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
