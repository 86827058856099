// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";

export const components = {
  avantages_optimum: dynamic(() => import("./AvantagesOptimum")),
  banner_promotion: dynamic(() => import("./BannerPromotion")),
  bar_promotion: dynamic(() => import("./BarPromotion")),
  barre_programme: dynamic(() => import("./BarreProgramme")),
  bilan_bloc: dynamic(() => import("./BilanBloc")),
  bilan_block: dynamic(() => import("./BilanBlock")),
  bloc_seo: dynamic(() => import("./BlocSeo")),
  card_succsess: dynamic(() => import("./CardSuccsess")),
  cart_advantages: dynamic(() => import("./CartAdvantages")),
  check_up_nutrition_head: dynamic(() => import("./CheckUpNutritionHead")),
  check_up_nutrition_questions: dynamic(
    () => import("./CheckUpNutritionQuestions"),
  ),
  club_account_block: dynamic(() => import("./ClubAccountBlock")),
  commitments_for_success: dynamic(() => import("./CommitmentsForSuccess")),
  dietchat_block: dynamic(() => import("./DietchatBlock")),
  doctor_nutritionist_faq: dynamic(() => import("./DoctorNutritionistFaq")),
  efficacite_long_terme: dynamic(() => import("./LongTermEffectiveness")),
  faq_block: dynamic(() => import("./FaqBlock")),
  first_bilan_step: dynamic(() => import("./FirstBilanStep")),
  five_success_keys: dynamic(() => import("./FiveSuccessKeys")),
  guide_bloc: dynamic(() => import("./GuideBloc")),
  head_optimum: dynamic(() => import("./HeadOptimum")),
  home_accelerer: dynamic(() => import("./HomeAccelerer")),
  home_head: dynamic(() => import("./HomeHead")),
  home_ope_info: dynamic(() => import("./HomeOpeInfo")),
  meals_head_text: dynamic(() => import("./MealsHeadText")),
  mon_conseil_diet: dynamic(() => import("./MonConseilDiet")),
  obesity_optimum: dynamic(() => import("./ObesityOptimum")),
  optimum_video: dynamic(() => import("./OptimumVideo")),
  original_agenda_head: dynamic(() => import("./OriginalAgendaHead")),
  original_application_head: dynamic(() => import("./OriginalApplicationHead")),
  original_club_head: dynamic(() => import("./OriginalClubHead")),
  original_sponsor_head: dynamic(() => import("./OriginalSponsorHead")),
  our_beliefs: dynamic(() => import("./OurBeliefs")),
  phenomics_optimum: dynamic(() => import("./PhenomicsOptimum")),
  prgram_head: dynamic(() => import("./PrgramHead")),
  principes_scientifiques: dynamic(() => import("./PrincipesScientifiques")),
  program_avis: dynamic(() => import("./ProgramAvis")),
  program_journee: dynamic(() => import("./ProgramJournee")),
  program_satisfaction: dynamic(() => import("./ProgramSatisfaction")),
  recettes_saines: dynamic(() => import("./RecettesSaines")),
  recommandations_optimum: dynamic(() => import("./RecommandationsOptimum")),
  result_bilan_objectif: dynamic(() => import("./ResultBilanObjectif")),
  slider_products: dynamic(() => import("./SliderProducts")),
  sponsorship_block: dynamic(() => import("./SponsorshipBlock")),
  stabilisation3_keys: dynamic(() => import("./Stabilisation3Keys")),
  stabilisation_block: dynamic(() => import("./StabilisationBlock")),
  stabilisation_delivery_block: dynamic(
    () => import("./StabilisationDeliveryBlock"),
  ),
  stabilisation_head: dynamic(() => import("./StabilisationHead")),
  stabilisation_keys: dynamic(() => import("./StabilisationKeys")),
  stabilisation_recommendation: dynamic(
    () => import("./StabilisationRecommendation"),
  ),
  stabilisation_videos: dynamic(() => import("./StabilisationVideos")),
  suivi_dietetique: dynamic(() => import("./SuiviDietetique")),
  three_reasons_consultation: dynamic(
    () => import("./ThreeReasonsConsultation"),
  ),
  trustpilot: dynamic(() => import("./Trustpilot")),
  what_optimum: dynamic(() => import("./WhatOptimum")),
};
